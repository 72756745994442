
import {defineComponent, ref, reactive, UnwrapRef, onMounted} from 'vue';
import {useRouter} from 'vue-router'
import {login, getX2serverInfo} from "@/api/api";
import {message} from 'ant-design-vue';

interface FormState {
  loginName: string;
  password: string;
}

export default defineComponent({
  setup() {
    const router = useRouter()
    const formState: UnwrapRef<FormState> = reactive({
      loginName: '',
      password: '',
    });

    const handleFinish = async () => {
      const res: any = await login(formState)
      if (res.Result === "OK") {
        sessionStorage.setItem("userId", res.Data.Id)
        sessionStorage.setItem("userName", res.Data.Name)
        if(res.Data.X2ServerID){
          sessionStorage.setItem("X2ServerID", res.Data.X2ServerID)
          const res2: any = await getX2serverInfo({id: res.Data.X2ServerID})
          sessionStorage.setItem("Token", res2.Token)
          sessionStorage.setItem("Host", res2.Host)
          router.push("/")
        }else{
          router.push("/x2server")
          message.error("当前还未绑定服务，请选择服务！")
        }
      }
    };
    return {
      formState,
      handleFinish,
    };
  },
});
